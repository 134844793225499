import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/TalkNoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Slides`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://paper.dropbox.com/doc/Szybciej-taniej--Aq~hdmj_QadS9qy49L4gJNF8Ag-8Y97bnBH3Lkl8KXGNzSSY"
      }}>{`Dropbox Paper`}</a>{` in Polish`}</p>
    <h3 {...{
      "id": "outline",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#outline",
        "aria-label": "outline permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Outline`}</h3>
    <p>{`A 15 minutes presentation, where I claim that React is a better tool to build
static websites than Pug, because components are superior to mixins and partials.
I introduced CSS-in-JS to improve the productivity and basics of TypeScript
to make teamwork silky smooth.`}</p>
    <ul>
      <li parentName="ul">{`TypeScript`}<ul parentName="li">
          <li parentName="ul">{`You have types in JavaScript, you just can’t write about them`}</li>
          <li parentName="ul">{`Bugs aren’t fun. Especially if we didn’t estimate them`}</li>
          <li parentName="ul">{`The stricter, the better`}</li>
          <li parentName="ul">{`Introduction to syntax`}</li>
        </ul></li>
      <li parentName="ul">{`React`}<ul parentName="li">
          <li parentName="ul">{`Building with components is simpler than smart design patters with
fancy acronyms (MCV, MVI, MVC, MVVM, MVP)`}</li>
          <li parentName="ul">{`Components introduce a `}<em parentName="li">{`contract`}</em>{` `}<inlineCode parentName="li">{`props -> element`}</inlineCode><pre parentName="li" {...{
              "className": "night-owl-no-italics vscode-highlight",
              "data-language": "ts"
            }}><code parentName="pre" {...{
                "className": "vscode-highlight-code"
              }}><span parentName="code" {...{
                  "className": "vscode-highlight-line"
                }}><span parentName="span" {...{
                    "className": "mtk3"
                  }}>{`type`}</span><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{` FunctionComponent<`}</span><span parentName="span" {...{
                    "className": "mtk12"
                  }}>{`P`}</span><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{`> `}</span><span parentName="span" {...{
                    "className": "mtk3"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{` (`}</span></span>{`
`}<span parentName="code" {...{
                  "className": "vscode-highlight-line"
                }}><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{`  `}</span><span parentName="span" {...{
                    "className": "mtk44"
                  }}>{`props`}</span><span parentName="span" {...{
                    "className": "mtk9"
                  }}>{`:`}</span><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{` `}</span><span parentName="span" {...{
                    "className": "mtk12"
                  }}>{`P`}</span><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{` `}</span><span parentName="span" {...{
                    "className": "mtk9"
                  }}>{`&`}</span><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{` { children`}</span><span parentName="span" {...{
                    "className": "mtk9"
                  }}>{`?:`}</span><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{` `}</span><span parentName="span" {...{
                    "className": "mtk12"
                  }}>{`ReactNode`}</span><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{` }`}</span></span>{`
`}<span parentName="code" {...{
                  "className": "vscode-highlight-line"
                }}><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{`) `}</span><span parentName="span" {...{
                    "className": "mtk3"
                  }}>{`=>`}</span><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{` `}</span><span parentName="span" {...{
                    "className": "mtk12"
                  }}>{`ReactElement`}</span><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{` `}</span><span parentName="span" {...{
                    "className": "mtk9"
                  }}>{`|`}</span><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{` `}</span><span parentName="span" {...{
                    "className": "mtk5"
                  }}>{`null`}</span><span parentName="span" {...{
                    "className": "mtk1"
                  }}>{`;`}</span></span></code></pre></li>
        </ul></li>
      <li parentName="ul">{`CSS-in-JS`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://kentcdodds.com/blog/colocation"
            }}>{`Colocation`}</a></li>
        </ul></li>
      <li parentName="ul">{`Live Coding of a website`}<ul parentName="li">
          <li parentName="ul">{`SSG vs SSR`}</li>
          <li parentName="ul">{`renderToStaticMarkup`}</li>
          <li parentName="ul">{`renderToString`}</li>
          <li parentName="ul">{`what is hydration?`}</li>
          <li parentName="ul">{`reinventing the wheel for educational purposes`}<ul parentName="li">
              <li parentName="ul">{`don’t do it at home, use Gatsby or react-snap`}</li>
            </ul></li>
        </ul></li>
    </ul>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .night-owl-no-italics {
background-color: #011627;
color: #d6deeb;
}

.night-owl-no-italics .mtk1 { color: #D6DEEB; }
.night-owl-no-italics .mtk2 { color: #011627; }
.night-owl-no-italics .mtk3 { color: #C792EA; }
.night-owl-no-italics .mtk4 { color: #82B1FF; }
.night-owl-no-italics .mtk5 { color: #ADDB67; }
.night-owl-no-italics .mtk6 { color: #637777; }
.night-owl-no-italics .mtk7 { color: #82AAFF; }
.night-owl-no-italics .mtk8 { color: #F78C6C; }
.night-owl-no-italics .mtk9 { color: #7FDBCA; }
.night-owl-no-italics .mtk10 { color: #FF5874; }
.night-owl-no-italics .mtk11 { color: #FFEB95; }
.night-owl-no-italics .mtk12 { color: #FFCB8B; }
.night-owl-no-italics .mtk13 { color: #7FDBCAFF; }
.night-owl-no-italics .mtk14 { color: #80CBC4; }
.night-owl-no-italics .mtk15 { color: #FF6363; }
.night-owl-no-italics .mtk16 { color: #57EAF1; }
.night-owl-no-italics .mtk17 { color: #5F7E97; }
.night-owl-no-italics .mtk18 { color: #ECC48D; }
.night-owl-no-italics .mtk19 { color: #B2CCD6; }
.night-owl-no-italics .mtk20 { color: #FAD430; }
.night-owl-no-italics .mtk21 { color: #FFFFFF; }
.night-owl-no-italics .mtk22 { color: #FF2C83; }
.night-owl-no-italics .mtk23 { color: #020E14; }
.night-owl-no-italics .mtk24 { color: #D3423E; }
.night-owl-no-italics .mtk25 { color: #EC5F67; }
.night-owl-no-italics .mtk26 { color: #8BD649; }
.night-owl-no-italics .mtk27 { color: #5CA7E4; }
.night-owl-no-italics .mtk28 { color: #A2BFFC; }
.night-owl-no-italics .mtk29 { color: #EF535090; }
.night-owl-no-italics .mtk30 { color: #ADDB67FF; }
.night-owl-no-italics .mtk31 { color: #697098; }
.night-owl-no-italics .mtk32 { color: #FF869A; }
.night-owl-no-italics .mtk33 { color: #DDDDDD; }
.night-owl-no-italics .mtk34 { color: #E0DEC6; }
.night-owl-no-italics .mtk35 { color: #CDEBF7; }
.night-owl-no-italics .mtk36 { color: #D9F5DD; }
.night-owl-no-italics .mtk37 { color: #6AE9F0; }
.night-owl-no-italics .mtk38 { color: #C789D6; }
.night-owl-no-italics .mtk39 { color: #BEC5D4; }
.night-owl-no-italics .mtk40 { color: #31E1EB; }
.night-owl-no-italics .mtk41 { color: #8EACE3; }
.night-owl-no-italics .mtk42 { color: #78CCF0; }
.night-owl-no-italics .mtk43 { color: #FAF39F; }
.night-owl-no-italics .mtk44 { color: #D7DBE0; }
.night-owl-no-italics .mtk45 { color: #7986E7; }
.night-owl-no-italics .mtki { font-style: italic; }
.night-owl-no-italics .mtkb { font-weight: bold; }
.night-owl-no-italics .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      